import React, { useState } from 'react';
import YouTube from 'react-youtube';
import './styles/MusicButton.css';

const playlists = [
  { id: 'PLG5D5C0LRDl89jG_gBI1PbNOjyrdbNK5G', name: 'Lakai Fully Flared Soundtrack' },
  { id: 'PLft4wWkI06ZtN4LXDpdnLKNQhlMptWf8m', name: 'Baker 3 Soundtrack' },
  { id: 'PL416C0EA752DDDF2E', name: 'Tony Hawk Pro Skater 2 Soundtrack' },
];

const MusicButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [selectedPlaylistName, setSelectedPlaylistName] = useState('');
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const onStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setInterval(() => {
        setCurrentTime(event.target.getCurrentTime());
      }, 1000);
    }
  };

  const handlePlay = () => {
    if (player) {
      player.playVideo();
    }
  };

  const handlePause = () => {
    if (player) {
      player.pauseVideo();
    }
  };

  const handleStop = () => {
    if (player) {
      player.stopVideo();
      setCurrentTime(0);
    }
  };

  const handlePlaylistSelection = (playlist) => {
    setSelectedPlaylist(playlist.id);
    setSelectedPlaylistName(playlist.name);
  };

  const opts = {
    height: '0',
    width: '0',
    playerVars: {
      listType: 'playlist',
      list: selectedPlaylist,
    },
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)} className="music-button">
        ♫ 
      </button>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>
            <h2>Select a Playlist</h2>
            <ul>
              {playlists.map((playlist) => (
                <li key={playlist.id}>
                  <button onClick={() => handlePlaylistSelection(playlist)}>
                    {playlist.name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="controls">
              <button onClick={handlePlay}>Play</button>
              <button onClick={handlePause}>Pause</button>
              <button onClick={handleStop}>Stop</button>
            </div>
            {selectedPlaylistName && (
              <div className="current-info">
                <p>Playing: {selectedPlaylistName}</p>
                <p>Current Time: {formatTime(currentTime)}</p>
              </div>
            )}
          </div>
        </div>
      )}
      {selectedPlaylist && (
        <YouTube videoId="" opts={opts} onReady={onReady} onStateChange={onStateChange} />
      )}
    </div>
  );
};

export default MusicButton;
