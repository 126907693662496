import React from 'react';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';
import './styles/Footer.css';
import logo from './logo.png';
import { Divider } from '@mui/material';


function Footer() {
  // Function to format the current date and time
  const formatDate = () => {
    const now = new Date();
    return now.toLocaleString(); // Adjust this to your preferred format
  };

  return (
    <footer className="App-footer">
      
      <img className="logo" src={logo} alt="Gorilla Funk Skateboards Logo" />
      <>
      <Divider orientation="horizontal" sx={{ color: 'black'}} flexItem />
      </>
      <div className="social-section">
        <p>Follow us on Instagram
        
        <a href="https://instagram.com/gorillafunkskateboards" className="social-icon"><FaInstagram /></a>
        </p>
      </div>
      <>
      <Divider orientation="horizontal" sx={{ color: 'black'}} flexItem />
      </>
      <div className="contact-info">
        <p>
          <FaEnvelope /> Email us at: <a className="underlinegone social-icon" href="mailto:skateboard@gorillafunk.website">skateboard@gorillafunk.website</a>
        </p>
        <p>Current Date and Time: {formatDate()}</p>
        
      </div>
    </footer>
  );
}

export default Footer;
