import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // or 'light'
    primary: {
      main: '#ff12c4b9', // Example vibrant purple
    },
    secondary: {
      main: '#282c34', // Example dark grey
    },
    text: {
      primary: '#fff', // White for dark mode
      secondary: '#fff', // White for dark mode
    },
    background: {
      default: '#000', // Black for dark mode
      paper: '#282c34', // Darker grey for background paper
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: 'Anton', // Your chosen font
    h1: {
      fontSize: '3.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.75rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '1rem',
      lineHeight: 1.43,
    },
    button: {
      textTransform: 'none', // Remove default uppercase
      fontSize: '1rem',
    },
  },
});

export default theme;