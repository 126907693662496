import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import logo from './logo.png';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { blueGrey, purple } from '@mui/material/colors';
import './styles/Header.css';

const StyledHeader = styled('header')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: ${blueGrey[900]};
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  font-family: "Anton", sans-serif;
  transition: transform 0.3s ease-in-out;
  transform: translateY(0);
  &.hidden {
    transform: translateY(-100%);
  }

  .logo-container {
    display: flex;
    align-items: center;
  }

  img {
    width: 100px;
    height: 100px;
    margin-right: 15px;
  }

  .typed-text {
    font-size: 1.8em;
    font-weight: 600; 
    margin-right: auto;
  }
`;

const StyledMenuButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 10px;
  }
`;

const StyledDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    .MuiPaper-root {
      width: 100%;
      height: 100%;
      background-color: ${blueGrey[900]};
      color: white;
      border: 2px solid ${purple[500]};
      border-radius: 0; /* Remove border radius for full screen */
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
      overflow: hidden;
      transition: transform 0.3s ease-in-out;
    }
  }
`;

const StyledList = styled(List)`
  &.MuiList-root {
    padding: 0;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const StyledListItemButton = styled(ListItemButton)`
  &.MuiListItemButton-root {
    padding: 20px;
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    transition: background-color 0.3s, transform 0.3s;
    &:hover {
      background-color: ${blueGrey[700]};
      color: white;
      transform: scale(1.05);
    }
  }
`;

const StyledCloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${purple[500]};
`;

function Header() {
  const [open, setOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleScroll = useCallback(() => {
    const currentScrollPosition = window.pageYOffset;
    setIsVisible(scrollPosition > currentScrollPosition || currentScrollPosition < 10);
    setScrollPosition(currentScrollPosition);
  }, [scrollPosition]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <StyledHeader className={`App-header ${!isVisible ? 'hidden' : ''}`}>
      <div className="logo-container">
        <Link to="/"><img className="header-logo" src={logo} alt="logo" /></Link>
        <TypeAnimation
          className="typed-text"
          sequence={[
            'Gorilla',
            1000,
            'Funk',
            1000,
            'Skateboards',
            1000
          ]}
          wrapper="h1"
          speed={40}
          style={{ fontSize: '1.8em' }}
          repeat={Infinity}
        />
      </div>

      <StyledMenuButton onClick={handleToggleDrawer}>
        <MenuIcon />
      </StyledMenuButton>

      <StyledDrawer
        anchor="right"
        open={open}
        onClose={handleToggleDrawer}
      >
        <StyledCloseButton onClick={handleToggleDrawer}>
          <CloseIcon />
        </StyledCloseButton>
        <StyledList>
          <ListItem key="home" disablePadding>
            <StyledListItemButton component={Link} to="/" onClick={handleToggleDrawer}>
              <ListItemText primary="Home" />
            </StyledListItemButton>
          </ListItem>
          <ListItem key="about" disablePadding>
            <StyledListItemButton component={Link} to="/about" onClick={handleToggleDrawer}>
              <ListItemText primary="About Us" />
            </StyledListItemButton>
          </ListItem>
          <ListItem key="lessons" disablePadding>
            <StyledListItemButton component={Link} to="/lessons" onClick={handleToggleDrawer}>
              <ListItemText primary="Lessons" />
            </StyledListItemButton>
          </ListItem>
          <ListItem key="contact" disablePadding>
            <StyledListItemButton component={Link} to="/contact" onClick={handleToggleDrawer}>
              <ListItemText primary="Contact" />
            </StyledListItemButton>
          </ListItem>
          <ListItem key="blog" disablePadding>
            <StyledListItemButton component={Link} to="/blog" onClick={handleToggleDrawer}>
              <ListItemText primary="Blog" />
            </StyledListItemButton>
          </ListItem>
        </StyledList>
      </StyledDrawer>
    </StyledHeader>
  );
}

export default Header;
