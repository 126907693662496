import React from 'react';
import './Blog.css';

const SafetyLessons = () => {
  return (
    <article className="blog-post">
      <h1>Skateboarding Safety: Essential Tips for Every Skater</h1>
      <p className="blog-date">Published on: 1-18-2024</p>
      <img src="/assetsblog/SafetyLessons.png" alt="Skateboarding Safety" className="blog-img" />
      <section className="blog-content">
        <p>Skateboarding is a thrilling and dynamic sport, but like all physical activities, it comes with its own set of risks. Prioritizing safety is crucial, whether you're a beginner learning the ropes or an experienced skater performing complex tricks.</p>
        
        <h2>Wear Proper Protective Gear</h2>
        <p>One of the most effective ways to prevent injuries while skateboarding is to wear the appropriate protective gear:</p>
        <ul>
          <li><strong>Helmet:</strong> A well-fitted helmet can significantly reduce the risk of head injuries.</li>
          <li><strong>Knee and Elbow Pads:</strong> Pads can protect your knees and elbows from scrapes and more serious injuries during falls.</li>
          <li><strong>Wrist Guards:</strong> They can help prevent wrist fractures and sprains.</li>
        </ul>

        <h2>Understand Your Limits</h2>
        <p>It’s important to be aware of your skill level and not attempt tricks or ride terrain that’s beyond your current abilities. Gradually build up your skills and confidence over time.</p>

        <h2>Skate in Safe Environments</h2>
        <p>Choose skateparks and skating areas that are well-maintained and free of hazards like debris, cracks, or excessive traffic. Avoid skating in wet or slippery conditions.</p>

        <h2>Stay Alert and Respect Others</h2>
        <p>When skating in public spaces or skateparks, be aware of your surroundings and other people using the space. Respect other skaters' turns and keep a safe distance.</p>

        <p>Remember, practicing safe skateboarding ensures that you and those around you can enjoy the sport to the fullest. Stay safe and keep skating!</p>
      </section>
    </article>
  );
};

export default SafetyLessons;
