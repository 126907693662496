import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import Blogs from './components/Blogs';
import GoSkate from './components/Blogs/GoSkate';
import SafetyLessons from './components/Blogs/SafetyLessons';
import GorillaFunkBlog from './components/Blogs/GorillaFunkBlog';
import { ThemeProvider } from '@mui/material/styles'; // Correct import
import theme from './theme';
import { CssBaseline } from '@mui/material';
import MusicButton from './components/MusicButton';
import ScrollToTop from './ScrollToTop';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

// Lazy load route components
const Home = React.lazy(() => import('./components/Home'));
const About = React.lazy(() => import('./components/About'));
const Lessons = React.lazy(() => import('./components/Lessons'));
const Contact = React.lazy(() => import('./components/Contact'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> 
      <HelmetProvider context={helmetContext}>
        <Router>
          <div className="container">
            <Header />
            <ScrollToTop />
            <MusicButton />
            <div className="App">
              <Helmet>
                <title>Virginia Beach Skateboarding Lessons and More - Gorilla Funk Skateboards</title>
                <link rel="canonical" href="https://www.gorillafunk.website/" />
              </Helmet>
              <main>
                <Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/blog/gorilla-funk-skateboards" element={<GorillaFunkBlog />} />
                    <Route path="/blog/go-skate" element={<GoSkate />} />
                    <Route path="/blog/safety-tips" element={<SafetyLessons />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/lessons" element={<Lessons />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/blog" element={<Blogs />} />
                    {/* Add more routes as needed */}
                  </Routes>
                </Suspense>
              </main>
            </div>
            <Footer />
          </div>
        </Router>
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
