import React from 'react';
import BlogPreview from './BlogPreview';
import blogsData from './Blogs/blogsData.json'; // Path to your JSON file
import { Helmet } from 'react-helmet-async';

const Blogs = () => {
  return (
    <div>
      <Helmet>
        <title>Latest Skateboarding Blogs - Gorilla Funk Skateboards</title>
        <meta name="description" content="Explore the latest skateboarding blogs from Gorilla Funk Skateboards. Stay updated with our skateboarding tips, news, and stories!" />
      </Helmet>
      {blogsData.map(blog => (
        <BlogPreview key={blog.id} blog={blog} />
      ))}
    </div>
  );
};

export default Blogs;
