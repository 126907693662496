// BlogPreview.js
import React from 'react';
import { Link } from 'react-router-dom';
import './styles/BlogPreview.css'; // Assuming you have a CSS file for styling

const BlogPreview = ({ blog }) => {
  return (
    <div className="blog-preview">
        <img src="/assets/gfunklogomain.png" alt="Skateboarding Safety" className="mainlogo" />
      <h2>{blog.title}</h2>
      <p className="blog-date">{blog.date}</p>
      <p className="blog-excerpt">{blog.excerpt}</p>
      <Link to={blog.url}>Read More</Link>
    </div>
  );
};

export default BlogPreview;
