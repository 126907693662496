import React from 'react';
import './Blog.css';

const BlogPost = () => {
  return (
    <article className="blog-post">
      <h1>Learning to Skateboard: A Beginner's Journey</h1>
      <p className="blog-date">Published on: 1-18-2024</p>
      <img src="/assetsblog/GoSkate.png" alt="Skateboarding Education" className="blog-img" />

      <section className="blog-content">
        <p>Welcome to the exciting world of skateboarding! Whether you're drawn to the thrill of cruising down the street or performing tricks at the skatepark, learning to skateboard can be an incredibly rewarding experience.</p>
        
        <h2>Getting Started</h2>
        <p>Before you hop on a board, it's important to understand the basics. Skateboarding requires balance, coordination, and a lot of practice. Here are some initial steps to get you started:</p>
        <ul>
          <li><strong>Choose the Right Skateboard:</strong> Select a board that fits your style and comfort. For beginners, a standard skateboard is a great option.</li>
          <li><strong>Wear Protective Gear:</strong> Safety should always come first. Make sure to wear a helmet, knee pads, and elbow pads.</li>
          <li><strong>Learn the Basics:</strong> Start by learning how to stand on the board, push off, and gently ride. Find a flat, smooth surface for practice.</li>
        </ul>

        <h2>Mastering the Basics</h2>
        <p>Once you're comfortable standing and moving on your skateboard, it's time to practice turning and stopping. These are crucial skills for navigating skateparks and city streets.</p>

        <h2>Advancing Your Skills</h2>
        <p>After mastering the basics, you can start learning tricks. Begin with simple maneuvers like ollies and kickflips. Remember, practice and patience are key.</p>

        <p>Skateboarding is not just a sport; it's a culture and a way of life. It's about expressing yourself and enjoying the freedom of the ride. So grab your board, and let's skate!</p>
      </section>
    </article>
  );
};

export default BlogPost;
